import { Image } from "@artists/uikit"
import { Typography } from "@mui/material"
import type { FC } from "react"
import { forwardRef } from "react"
import { useToggle } from "react-use"

import { ImageModal } from "./modal"
import { MediaLabel, MediaRoot, Zoomable } from "./styles"
import type { CardMediaProps } from "./types"

export const CardMedia: FC<CardMediaProps> = forwardRef((props, ref) => {
	const { image, label, imageModal, slotProps, slots, ...other } = props
	const imageProps = typeof image === "string" ? { src: image } : image
	const [open, toggle] = useToggle(false)

	const Root = slots?.root ?? MediaRoot
	const Label = slots?.label ?? MediaLabel

	return (
		<>
			<Root ref={ref} {...slotProps?.root} {...other}>
				<Zoomable onClick={toggle}>
					<Image {...imageProps} style={{ paddingTop: "100%" }} />
				</Zoomable>

				{label && (
					<Label {...slotProps?.label}>
						<Typography color='inherit'>{label}</Typography>
					</Label>
				)}
			</Root>

			{imageModal && (
				<ImageModal
					open={open}
					image={image}
					slotProps={slotProps}
					toggle={toggle}
				/>
			)}
		</>
	)
})
