import { Card, CardContent, CardSubTitle, CardTitle } from "@artists/uikit"
import type { SvgIconComponent } from "@mui/icons-material"
import { Box } from "@mui/material"
import type { FC, ReactNode } from "react"

import { Favorite } from "../favorite"
import CrossIcon from "./cross.icon.svg"

interface Props {
	title: string
	actions?: ReactNode
	subtitle: JSX.Element | string
	Icon: SvgIconComponent
}
export const ActionCard: FC<Props> = ({ Icon, actions, title, subtitle }) => (
	<Card>
		<Box pt='100%' position='relative' bgcolor='#ddd'>
			<Box
				sx={{
					inset: "0",
					display: "flex",
					position: "absolute",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CrossIcon
					style={{
						inset: 0,
						width: "100%",
						height: "100%",
						position: "absolute",
					}}
				/>

				<Box
					width={33}
					height={33}
					display='grid'
					bgcolor='white'
					borderRadius='50%'
					sx={{ placeContent: "center" }}
					zIndex={1}
				>
					<Icon color='action' />
				</Box>
			</Box>
		</Box>

		<CardContent>
			{actions && <Box mb={2}>{actions}</Box>}

			<CardTitle>{title}</CardTitle>

			<CardSubTitle>{subtitle}</CardSubTitle>
		</CardContent>

		<Favorite disabled sx={{ m: 1, mt: -1 }} />
	</Card>
)
