import { type FC, forwardRef, Fragment, useEffect, useState } from "react"

import type { TimeProps } from "./types"

export const Time: FC<TimeProps> = forwardRef(
	({ getTime, children, parent, ...props }, ref) => {
		const [time, setTime] = useState(() => getTime?.() ?? children)
		const Parent = parent ? parent : Fragment

		useEffect(() => {
			if (getTime) setTime(getTime())
		}, [getTime])

		return (
			<Parent>
				<time suppressHydrationWarning ref={ref} {...props}>
					{time}
				</time>
			</Parent>
		)
	},
)
