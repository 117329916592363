import { HideImage } from "@mui/icons-material"
import { Box } from "@mui/material"
import { AsyncImage } from "loadable-image"
import type { FC } from "react"

import { Blur, Loader } from "./image.styles"
import type { ImageProps } from "./image.types"

export const Image: FC<ImageProps> = ({
	src,
	srcSet,
	lowSrc,
	alt,
	style = {},
	sources,
}) => (
	<AsyncImage
		src={src}
		alt={alt}
		srcSet={srcSet}
		sources={sources}
		style={{ height: "100%", width: "100%", objectFit: "cover", ...style }}
		loader={
			lowSrc ? (
				<Blur src={lowSrc} />
			) : (
				<Loader data-testid='image-loader' animation='wave' />
			)
		}
		error={
			<Box data-testid='image-error' bgcolor='#eee' color='text.disabled'>
				<HideImage
					sx={{
						position: "absolute",
						inset: 0,
						width: "50%",
						height: "50%",
						m: "auto",
					}}
					color='inherit'
				/>
			</Box>
		}
	/>
)
