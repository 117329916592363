import { getIds, getSelectors } from "@artists/shared-testkit"

const ID_STRINGS = [
	"modal",
	"closeModal",
	"modalAccept",
	"modalReject",
	"share",
] as const

export const commonIds = getIds(...ID_STRINGS)

export const commonSelectors = getSelectors(...ID_STRINGS)
