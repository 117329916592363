import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"

export const MediaRoot = styled(Box, {
	name: "CardMedia",
	slot: "root",
})({
	padding: 0,
	width: "100%",
	display: "block",
	cursor: "pointer",
	textTransform: "none",
	position: "relative",
	overflow: "hidden",
})

export const Zoomable = styled(Box, {
	name: "Zoomable",
	slot: "zoomable",
})(({ theme: { transitions } }) => ({
	willChange: "transform",
	transition: transitions.create("transform", {
		duration: transitions.duration.complex,
	}),
	"&:hover, &:focus": { transform: "scale(1.05)" },
}))

export const MediaLabel = styled(Box, {
	name: "MediaLabel",
	slot: "label",
})(({ theme: { palette, unstable_sx: sx } }) =>
	sx({
		py: 1,
		px: 2,
		lef: 0,
		bottom: 0,
		zIndex: 1,
		opacity: 0.9,
		position: "absolute",
		color: palette.text.primary,
		bgcolor: palette.paper.secondary,
	}),
)
