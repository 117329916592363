import { challengeDetailsIds } from "@artists/challenges-testkit"
import { Breadcrumbs, Link, Typography } from "@mui/material"
import type { SxProps, Theme } from "@mui/system"
import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"

import { Root } from "../../../utils"

interface Props {
	sx?: SxProps<Theme>
}

export const ChallengeBreadcrumbs = ({ sx }: Props) => {
	const { t } = useTranslation("common")

	return (
		<Breadcrumbs
			data-testid={challengeDetailsIds.breadcrumbs}
			aria-label='breadcrumb'
			sx={sx}
		>
			<Link
				data-testid={challengeDetailsIds.homeBreadcrumb}
				component={RouterLink}
				to={Root.path}
				color='warning.dark'
			>
				<Typography fontSize='0.75em' textTransform='uppercase'>
					{t("challengeBreadcrumbs.designChallenges")}
				</Typography>
			</Link>
			<div />
		</Breadcrumbs>
	)
}
