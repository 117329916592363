import { camelCase } from "camel-case"

import type { IDs } from "./types"

export function getSelectors<T extends string>(...ids: T[]) {
	return ids.reduce(
		(obj, val) => ({ ...obj, [camelCase(val)]: getSelector(val) }),
		{} as IDs<T>,
	)
}

export function getIds<T extends string>(...ids: T[]) {
	return ids.reduce(
		(obj, val) => ({ ...obj, [camelCase(val)]: val }),
		{} as IDs<T>,
	)
}

export const getId = (id: string, param?: string) =>
	param ? `${id}-${param}` : id

export const getSelector = (id: string) => `[data-testid="${id}"]`

export const getAlias = (val: string) => `@${val}`
