import { alpha, Dialog } from "@mui/material"
import type { FC } from "react"

import { Image } from "../../../image"
import type { CardMediaProps } from "../types"

type Props = Pick<CardMediaProps, "image" | "slots" | "slotProps"> & {
	open: boolean
	toggle?: () => void
}
export const ImageModal: FC<Props> = ({ image, open, toggle, slotProps }) => {
	const imageProps = typeof image === "string" ? { src: image } : image

	return (
		<Dialog
			fullWidth
			open={open}
			maxWidth='sm'
			onClose={toggle}
			sx={{ "& .MuiPaper-root": { m: 3, width: "100%" } }}
			slotProps={{
				backdrop: { sx: { bgcolor: alpha("#fff", 0.85) } },
				...slotProps?.modal?.root?.slotProps,
			}}
			{...slotProps?.modal?.root}
		>
			<Image
				src={imageProps.src}
				alt={imageProps.alt}
				style={{
					width: "100%",
					height: "auto",
					paddingTop: "100%",
					borderRadius: 4,
					...slotProps?.modal?.image?.style,
				}}
				{...slotProps?.modal?.image}
			/>
		</Dialog>
	)
}
