import { List as ArtistList } from "@artists/uikit"
import { styled } from "@mui/material/styles"

export const List = styled(ArtistList)(
	({ theme: { breakpoints, unstable_sx: sx } }) =>
		sx({
			gap: 3,
			display: "grid",
			width: "100%",
			gridTemplateColumns: "repeat(auto-fill, minmax(165px, 1fr))",
			[breakpoints.up(355)]: {
				"&": { gridTemplateColumns: "repeat(2, 1fr)" },
			},
			[breakpoints.up(700)]: {
				"&": { gridTemplateColumns: "repeat(3, 1fr)" },
			},
			[breakpoints.up(1000)]: {
				"&": { gridTemplateColumns: "repeat(4, 1fr)", gap: 4 },
			},
		}),
)
