import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client"

const resetMutation = gql`
	mutation Reset {
		reset
	}
`

const seedMutation = gql`
	mutation Reset {
		seed
	}
`

const getApolloClient = (url: string) =>
	new ApolloClient({
		cache: new InMemoryCache(),
		defaultOptions: {
			watchQuery: {
				fetchPolicy: "no-cache",
			},
			query: { fetchPolicy: "no-cache" },
			mutate: { fetchPolicy: "no-cache" },
		},
		link: new HttpLink({
			fetch,
			uri: url,
		}),
	})

const seed = (url: string) =>
	getApolloClient(url).mutate({ mutation: seedMutation })
const reset = (url: string) =>
	getApolloClient(url).mutate({ mutation: resetMutation })

export { seed, reset }
