import {
	getId,
	getIds,
	getSelector,
	getSelectors,
} from "@artists/shared-testkit"

const ID_STRINGS = [
	"subscribe",
	"prizesSection",
	"prizeText",
	"shopPastChallenges",
	"pastChallenges",
	"currentChallenges",
	"howToEnter",
] as const

const homeDynamicIds = {
	Challenge: (param: string) => getId("Challenge", param),
	prize: (param: string) => getId("prize", param),
}

const homeDynamicSelectors = {
	Challenge: (param: string) => getSelector(homeDynamicIds.Challenge(param)),
	prize: (param: string) => getSelector(homeDynamicIds.prize(param)),
}

export const homeChallengesIds = { ...getIds(...ID_STRINGS), ...homeDynamicIds }

export const homeChallengesSelectors = {
	...getSelectors(...ID_STRINGS),
	...homeDynamicSelectors,
}
