import { ChallengeEntryStatus } from "@artists/gql/design-challenges"
import type { SvgIconComponent } from "@mui/icons-material"
import { DoDisturb, Lock } from "@mui/icons-material"
import { Link } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"

import { designerLink } from "../../../utils"

interface EntryWithStatus {
	entryStatus: ChallengeEntryStatus
	user: {
		userName: string
	}
}

interface Action {
	Icon: SvgIconComponent
	title: string
	subtitle: JSX.Element | string
}
type ActionsStatus = Partial<Collection<ChallengeEntryStatus, Action>>

export const useActionStatuses = (entry?: EntryWithStatus) => {
	const [t] = useTranslation("common")

	if (!entry) return null

	const config: ActionsStatus = {
		[ChallengeEntryStatus.PrivateNotForSale]: {
			Icon: Lock,
			title: t("challengeActionStatuses.private.title"),
			subtitle: t("challengeActionStatuses.private.subtitle"),
		},
		[ChallengeEntryStatus.Deleted]: {
			Icon: DoDisturb,
			title: t("challengeActionStatuses.deleted.title"),
			subtitle: (
				<Trans
					ns='common'
					i18nKey='challengeActionStatuses.deleted.subtitle'
					values={{ designer: entry.user.userName }}
				>
					<Link
						target='_blank'
						href={designerLink(entry.user.userName)}
					/>
				</Trans>
			),
		},
	}

	return entry.entryStatus ? config[entry.entryStatus] : null
}
