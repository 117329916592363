import {
	getId,
	getIds,
	getSelector,
	getSelectors,
} from "@artists/shared-testkit"

const ID_STRINGS = [
	"challengesEntered",
	"votesSubmitted",
	"winnerTimes",
	"stats",
	"entryStatus",
	"withdraw",
	"share",
	"edit",
] as const

const entriesDynamicIds = {
	entry: (param: string) => getId("entry", param),
}

const entriesDynamicSelectors = {
	entry: (param: string) => getSelector(entriesDynamicIds.entry(param)),
}
export const myEntriesIds = { ...getIds(...ID_STRINGS), ...entriesDynamicIds }

export const myEntriesSelectors = {
	...getSelectors(...ID_STRINGS),
	...entriesDynamicSelectors,
}
