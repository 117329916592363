import { type MutationOptions, useMutation } from "@apollo/client"
import {
	AddFavoriteDocument,
	RemoveFavoriteDocument,
} from "@artists/gql/design-challenges"
import { Favorite as FavoriteIcon, FavoriteBorder } from "@mui/icons-material"
import { CircularProgress, IconButton } from "@mui/material"
import type { FC, MouseEvent } from "react"

import { useAuth } from "../../../api/hooks/@shared"
import type { FavoriteProps } from "./types"

export const Favorite: FC<FavoriteProps> = props => {
	const { authorized } = useAuth()
	const [addFavorite, { loading: addLoading }] =
		useMutation(AddFavoriteDocument)
	const [removeFavorite, { loading: removeLoading }] = useMutation(
		RemoveFavoriteDocument,
	)

	if (!authorized) return null

	if (props.disabled) {
		return (
			<IconButton {...props}>
				<FavoriteBorder />
			</IconButton>
		)
	}

	const { entry, onClick, ...buttonProps } = props
	const Icon = entry.isFavorite ? FavoriteIcon : FavoriteBorder
	const loading = !entry && (addLoading || removeLoading)

	const click = (e: MouseEvent<HTMLButtonElement>) => {
		const { challengeId, designId, isFavorite } = entry

		const change = isFavorite
			? {
					mutation: removeFavorite,
					query: "deleteDesignFromUserFavorites",
				}
			: { mutation: addFavorite, query: "addDesignToUserFavorites" }

		change.mutation({
			variables: { dto: { challengeId, designId } },
			optimisticResponse: {
				[change.query]: { ...entry, isFavorite: !isFavorite },
			} as MutationOptions["optimisticResponse"],
		})

		onClick?.(e)
	}

	return (
		<IconButton {...buttonProps} onClick={click}>
			{loading ? <CircularProgress size={24} /> : <Icon />}
		</IconButton>
	)
}
