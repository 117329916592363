import { Pagination as MuiPagination, PaginationItem } from "@mui/material"
import type { FC } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { useTypedSearchParams } from "react-router-typesafe-routes/dom"

import { Root } from "../../../utils"
import type { PaginationProps } from "./pagination.types"

export const Pagination: FC<PaginationProps> = ({ pagination, ...props }) => {
	const [sp] = useSearchParams()
	const [{ offset }] = useTypedSearchParams(Root)
	const { total, limit } = pagination
	const count = Math.ceil(total / limit)
	const page = Math.ceil(offset / limit + 1)

	if (total < limit) return null

	return (
		<MuiPagination
			page={page}
			count={count}
			renderItem={item => {
				const nextSearchParams = new URLSearchParams(sp)
				nextSearchParams.set(
					"offset",
					`${((item.page ?? 1) - 1) * limit}`,
				)

				return (
					<PaginationItem
						component={Link}
						to={`?${nextSearchParams.toString()}`}
						{...item}
					/>
				)
			}}
			{...props}
		/>
	)
}
