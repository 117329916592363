import {
	getId,
	getIds,
	getSelector,
	getSelectors,
} from "@artists/shared-testkit"

const ID_STRINGS = [
	"breadcrumbs",
	"homeBreadcrumb",
	"title",
	"enterDesign",
	"timeLine",
	"challengeIntro",
	"challengeDetails",
	"viewMore",
] as const

const challengeDynamicIds = {
	CTAButton: (param: string) => getId("CTAButton", param),
}

const challengeDynamicSelectors = {
	CTAButton: (param: string) =>
		getSelector(challengeDynamicIds.CTAButton(param)),
}

export const challengeDetailsIds = {
	...getIds(...ID_STRINGS),
	...challengeDynamicIds,
}

export const challengeDetailsSelectors = {
	...getSelectors(...ID_STRINGS),
	...challengeDynamicSelectors,
}
