import { Skeleton } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Blur = styled("img")({
	backgroundColor: "#eee",
	objectFit: "cover",
	filter: "blur(25px)",
	transform: "scale(1)",
})

export const Loader = styled(Skeleton)({
	transform: "none",
})
