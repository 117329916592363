import { Stack } from "@mui/material"
import { Children, type FC } from "react"

import { Empty } from "../empty"
import type { ListProps } from "./list.types"
import { normalizeEmpty } from "./utils"

export const List: FC<ListProps> = ({
	loading = false,
	empty = "No items",
	loader = null,
	slots,
	children,
	...props
}) => {
	const isEmpty = !loading && Children.count(children) === 0

	if (isEmpty) {
		if (slots?.empty) return slots.empty

		return (
			<Stack {...props} style={{ display: "flex" }}>
				<Empty {...normalizeEmpty(empty)} />
			</Stack>
		)
	}

	return <Stack {...props}>{loading ? loader : children}</Stack>
}
