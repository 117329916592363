import { ClipBorder } from "@artists/uikit"
import {
	Box,
	type BoxProps,
	Button,
	type ButtonProps,
	Stack,
	Typography,
	type TypographyProps,
} from "@mui/material"
import { styled } from "@mui/material/styles"

export const Card = styled(({ children, ...props }: BoxProps) => (
	<Box {...props}>
		<ClipBorder className='card-children'>{children}</ClipBorder>
	</Box>
))({
	borderRadius: 5,
	overflow: "hidden",
	position: "relative",
	textTransform: "none",
	boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.1)",
})

export const CardContent = styled(Stack)(({ theme: { unstable_sx: sx } }) =>
	sx({ p: 2 }),
)

export const CardTitle = styled(
	({ noWrap = true, ...props }: TypographyProps) => (
		<Typography {...props} variant='subtitle1' noWrap={noWrap} />
	),
)({})

export const CardSubTitle = styled(
	({ noWrap = true, ...props }: TypographyProps) => (
		<Typography
			{...props}
			noWrap={noWrap}
			color='text.secondary'
			lineHeight='1rem'
			variant='body3'
		/>
	),
)({})

export const CardAction = styled((props: ButtonProps) => (
	<Button size='small' color='secondary' {...props} />
))({ whiteSpace: "nowrap" })
