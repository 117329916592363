import {
	getId,
	getIds,
	getSelector,
	getSelectors,
} from "@artists/shared-testkit"

const ID_STRINGS = [
	"title",
	"designerName",
	"designName",
	"goHomeButton",
	"errorMessage",
	"controls",
	"form",
	"buttons",
	"submitEntry",
	"editEntry",
	"cancelEntry",
	"challengeSelector",
	"themeCheckBox",
	"aiCheckBox",
	"designDescription",
	"designNameEdit",
] as const

const submissionDynamicIds = {
	availability: (param: string) => getId("availability", param),
}

const submissionDynamicSelectors = {
	eligibility: (param: string) =>
		getSelector(submissionDynamicIds.availability(param)),
}

export const submissionIds = {
	...getIds(...ID_STRINGS),
	...submissionDynamicIds,
}

export const submissionSelectors = {
	...getSelectors(...ID_STRINGS),
	...submissionDynamicSelectors,
}
