import { Box, Stack, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import type { FC } from "react"

import type { EmptyProps } from "./empty.types"

export const Empty: FC<EmptyProps> = ({ title, subtitle, sx, ...props }) => (
	<Box
		width='100%'
		height={250}
		display='grid'
		borderRadius='5px'
		bgcolor={grey[200]}
		color='text.secondary'
		sx={{ placeItems: "center", textAlign: "center", ...sx }}
		{...props}
	>
		<Stack gap={1}>
			<Typography variant='h5' component='p'>
				{title}
			</Typography>
			{subtitle && (
				<Typography data-testid='empty-subtitle'>{subtitle}</Typography>
			)}
		</Stack>
	</Box>
)
