import {
	getId,
	getIds,
	getSelector,
	getSelectors,
} from "@artists/shared-testkit"

const ID_STRINGS = [
	"voteCount",
	"entryTitle",
	"voteButton",
	"artistName",
	"modalLogin",
	"modalSign",
	"voteAsGuest",
	"mergeVotes",
	"submitVotes",
	"removeVote",
	"returnToVoting",
	"votingList",
] as const

const votingDynamicIds = {
	entry: (param: string) => getId("entry", param),
}

const votingDynamicSelectors = {
	entry: (param: string) => getSelector(votingDynamicIds.entry(param)),
}

export const votingIds = { ...getIds(...ID_STRINGS), ...votingDynamicIds }

export const votingSelectors = {
	...getSelectors(...ID_STRINGS),
	...votingDynamicSelectors,
}
