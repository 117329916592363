import { getIds, getSelectors } from "@artists/shared-testkit"

const ID_STRINGS = [
	"home-btn",
	"NavigateNextIcon",
	"past-challenge-card",
	"clear-btn",
	"search-field",
] as const

export const pastChallengesIds = getIds(...ID_STRINGS)

export const pastChallengesSelectors = getSelectors(...ID_STRINGS)
